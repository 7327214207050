import React from 'react';
import RequestDemoForm from './requestDemoForm';
import globalstyle from '../../style.module.css';
import demoStyle from './demo.module.css';
import { Row, Col } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';

const RequestDemo = () => {

const items = [
        {item:"All-in-one solution"},
        {item:"First of it’s kind within the MENA region "},
         {item:"Strong team with deep market, technology knowledge and vision to succeed "},
        {item:"Fully decentralized "},
        {item:"Built by commodity traders for commodity traders "}
]

return (
<div className={demoStyle.demoSection}>
<div className={globalstyle.container}>
<Row gutter={[24]}>
<Col xs={24} sm={12} md={16}>
    

<div className={demoStyle.getStartedHeading}>
    Get Started Now</div>
  
{items.map((it)=>(
<p className={demoStyle.getStartedItem}>
<Row gutter={[24]}>
<Col md={2} sm={2} xs={2}> 
<CheckCircleOutlined style={{color:"#FFA96E",fontSize:"20px"}} />
</Col>
<Col md={22} sm={22} xs={22}> 
{it.item} </Col>
 </Row>
</p>
))}

</Col>
 <Col xs={24} sm={12} md={8}>
<RequestDemoForm />
</Col>
</Row>
</div>
</div>
);
};

export default RequestDemo;