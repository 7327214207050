import React from 'react';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import { Col, Row } from 'antd';
import { DoubleRightOutlined } from '@ant-design/icons';
import modules from './modules.module.css';
import globalstyle from '../../style.module.css';
import fynyamarket from './marketplace.png';
import lcicon from '../../assets/img/lcicon.png';
import cashicon from '../../assets/img/cashicon.png';
import cryptocoin from '../../assets/img/cryptocoin.png';

import ProductsSlider from '../home/productsSlider';
import RequestDemo from '../requestDemo/requestDemo';
import products from './products.module.css'


const FynyaMarket = () => {
return (
<div>

<MetaTags>
            <title>Fynya | Global Marketplace</title>
            <meta name="description" content="Become a part of the global commodities marketplace quickly and easily.
" />
            <meta property="og:title" content="Fynya | Global Marketplace" />
            <meta property="og:image" content="../../assets/img/logo.png" />
</MetaTags>

 <div className={globalstyle.imageBg}>      
<div className={globalstyle.openingText}>
<span className={globalstyle.openingTextHeader}>  Tokenised Marketplace. </span>

   <br />
     <span className={globalstyle.openingTextDescription} style={{padding:"20px"}}>
Connecting producers with consumers 
</span> 
        <br/>
 
 
 
</div>
</div>


 

<div className={globalstyle.container}>
<div className={globalstyle.bodySection}>



  <Row gutter={[24]}>
<Col md={12} xs={24}> 
<div>

<p className={modules.description}>

Become a part of the global commodities marketplace quickly and easily. Trade the most popular commodities, such as Wheat, Coffee, Corn, Soya, Wheat, Rice, Natural Gas, Crude Oil, Gold, Silver from the comfort of your home, and access some of the World’s most important and well-established markets.


This module aims at simplification of International Commodity Trading Life Cycle by connecting producers with consumers from across the world, backed by  an ecosystem of in-house custom built modules. 


</p>


</div>
</Col>
<Col md={12} xs={0} className={globalstyle.bgAboutPicture}> 
<img className={globalstyle.picture} src={fynyamarket} />
</Col>
</Row>
 
 
</div>
</div>

 

<div style={{backgroundColor:"#271D58"}}>
<div className={globalstyle.container}>

<div className={globalstyle.bodySection}>     

<div className={globalstyle.heading} style={{color:"#fff",paddingBottom:"9px"}}>
  Are you a Commodity Trader ?
  </div>
<div className={globalstyle.heading} style={{color:"#fff",paddingBottom:"29px",fontSize:"18px"}}>Start Selling Commodities In 3 Easy Steps</div>


<Row gutter={[24]}>
<Col md={8} xs={24}> 
<p className={modules.descriptionWhite}>
<div className={globalstyle.subHeading}>Register</div>

<Row gutter={[24]}>
<Col md={4} xs={4}>
<div className={globalstyle.stepNumber}>
1
</div>
</Col>
<Col md={20} xs={20}>
<p className={globalstyle.stepNumberText}>
Create a new account by filling out the application form and submitting.

</p>
</Col>
</Row>
</p>

</Col>
<Col md={8} xs={24}> 
<p className={modules.descriptionWhite}>
<div className={globalstyle.subHeading}>List Commodities</div>

<Row gutter={[24]}>
<Col md={4} xs={4}>
<div className={globalstyle.stepNumber}>
2
</div>
</Col>
<Col md={20} xs={20}>
<p className={globalstyle.stepNumberText}>
Start listing your commodities by uploading pictures of the commodities you wish to list, commodity specifications and price.
</p>
</Col>
</Row>
</p>

</Col>
<Col md={8} xs={24}> 
<p className={modules.descriptionWhite}>
<div className={globalstyle.subHeading}>Start Earning</div>

<Row gutter={[24]}>
<Col md={4} xs={4}>
<div className={globalstyle.stepNumber}>
3
</div>
</Col>
<Col md={20} xs={20}>
<p className={globalstyle.stepNumberText}>
As soon as commodities are live on Fynya you are ready to start earning.
</p>
</Col>
</Row>
</p>

</Col>
 
</Row>

<Row gutter={[24]}>
<Col md={9} sm={9}  xs={0}>
</Col>
<Col md={6} sm={6} xs={24}> 

<Link to="/contact"><div className={globalstyle.menuButton} style={{marginTop:"30px"}}> Get Started</div></Link>
</Col>
</Row>
</div>
</div>
</div>




 

<div style={{backgroundColor:"#5B2BBC"}}>
<div className={globalstyle.container}>

<div className={globalstyle.bodySection}>     

<div className={globalstyle.heading} style={{color:"#fff",paddingBottom:"9px"}}>
Do you wish to buy commodities ?
  </div>
<div className={globalstyle.heading} style={{color:"#fff",paddingBottom:"29px",fontSize:"18px"}}>Get Started In 3 Easy Steps</div>


<Row gutter={[24]}>
<Col md={8} xs={24}> 
<p className={modules.descriptionWhite}>
<div className={globalstyle.subHeading}>Register</div>

<Row gutter={[24]}>
<Col md={4} xs={4}>
<div className={globalstyle.stepNumber}>
1
</div>
</Col>
<Col md={20} xs={20}>
<p className={globalstyle.stepNumberText}>
Create a new account by filling out the application form and submitting.

</p>
</Col>
</Row>
</p>

</Col>
<Col md={8} xs={24}> 
<p className={modules.descriptionWhite}>
<div className={globalstyle.subHeading}>Browse commodities</div>

<Row gutter={[24]}>
<Col md={4} xs={4}>
<div className={globalstyle.stepNumber}>
2
</div>
</Col>
<Col md={20} xs={20}>
<p className={globalstyle.stepNumberText}>
Browse wide variety of commodities listed by different commodity traders.
</p>
</Col>
</Row>
</p>

</Col>
<Col md={8} xs={24}> 
<p className={modules.descriptionWhite}>
<div className={globalstyle.subHeading}>Select Payment Method</div>

<Row gutter={[24]}>
<Col md={4} xs={4}>
<div className={globalstyle.stepNumber}>
3
</div>
</Col>
<Col md={20} xs={20}>
<p className={globalstyle.stepNumberText}>
Wide selection of payment methods. Pay using Fynya token, Letter of Credit (LC) or Fiat currency.
 <Link to="/fynya-pay"><span style={{marginLeft:"7px"}} >Learn more</span> <DoubleRightOutlined /></Link>
</p>
</Col>
</Row>
</p>

</Col>
 
</Row>

<Row gutter={[24]}>
<Col md={9} sm={9}  xs={0}>
</Col>
<Col md={6} sm={6} xs={24}> 

<Link to="/contact"><div className={globalstyle.menuButton} style={{marginTop:"30px"}}> Get Started</div></Link>
</Col>
</Row>
</div>
</div>
</div>


<div className={globalstyle.container}>

<div className={globalstyle.bodySection}>

<div className={globalstyle.heading}>Payment Methods</div>

<Row gutter={[24]}>


<Col md={8} sm={8} xs={24}> 
 
 <div className={products.advantage}>      
{/* <span className={products.productIcon}><FileProtectOutlined /></span> */}
<img className={globalstyle.picture} style={{width:"100px"}} src={lcicon} />

<div className={globalstyle.heading}>Letter Of Credit</div>

<p style={{textAlign:"center"}}>
Digitized Blockckchain powered letter of credit (LC) transactions.
<Link to="/blockchain-letter-of-credit"><span style={{marginLeft:"7px"}} >Learn more</span> <DoubleRightOutlined /></Link>
</p>
 </div>
</Col>

<Col md={8} sm={8} xs={24}> 
 <div className={products.advantage}>      
{/* <span className={products.productIcon}><BankOutlined /></span> */}
<img className={globalstyle.picture} src={cryptocoin} style={{width:"100px"}} />

<div className={globalstyle.heading}>Fynya Token

</div>
<p style={{textAlign:"center"}}>
Minimal fees, fast and secure cryptocurrency powered transactions.
<Link to="/fynya-pay"><span style={{marginLeft:"7px"}} >Learn more</span> <DoubleRightOutlined /></Link>

 </p>
</div>
 </Col>

<Col md={8} sm={8} xs={24}> 
 <div className={products.advantage}>      
{/* <span className={products.productIcon}><FileProtectOutlined /></span> */}
<img className={globalstyle.picture} src={cashicon} style={{width:"100px"}} />

<div className={globalstyle.heading}>Fiat Currency
</div>
<p style={{textAlign:"center"}}>
Cash, Bank transfer payments using a wide range of fiat currencies. </p>
</div>
 </Col>

</Row>
 </div>
</div>





{/* 

<div className={globalstyle.container}>


<span className={globalstyle.mainHeading}>Products</span>

<div className={products.productsSection}>



<Row gutter={[4, 4]}>

<Col md={12} sm={12} xs={24}> 
 <motion.div
  initial={{ scale: 0 }}
  animate={{ rotate: 0, scale: 1 }}
  transition={{
    type: "spring",
    stiffness: 260,
    damping: 20
  }}
>
 <div className={products.product}>      
<div className={products.productItem}>      
 <div className={globalstyle.heading} style={{color:"#fff",paddingBottom:"9px",marginLeft:"43px"}}>
  Do you wish to buy commodities ?
  </div>
  <p className={products.mainProduct}>
  <div className={globalstyle.heading} style={{marginBottom:"10px",marginLeft:"43px"}}>Buy Commodities in 3 easy steps.</div>


  <p className={modules.descriptionWhite}>
<div className={globalstyle.subHeading}>Create Account</div>

<Row gutter={[24]}>
<Col md={3} xs={4}>
<div className={globalstyle.stepNumber}>
1
</div>
</Col>
<Col md={21} xs={20}>
<p className={globalstyle.stepNumberText}>
Create a new account by filling out the application form.
</p>
</Col>
</Row>

<div className={globalstyle.subHeading}>Browse commodities</div>

<Row gutter={[24]}>
<Col md={3} xs={4}>
<div className={globalstyle.stepNumber}>
2
</div>
</Col>
<Col md={21} xs={20}>
<p className={globalstyle.stepNumberText}>
Browse wide variety of commodities listed by different commodity traders.
</p>
</Col>
</Row>

<div className={globalstyle.subHeading}>Select Payment Method</div>

<Row gutter={[24]}>
<Col md={3} xs={4}>
<div className={globalstyle.stepNumber}>
3
</div>
</Col>
<Col md={21} xs={20}>
<p className={globalstyle.stepNumberText}>
Wide selection of payment methods. Pay using Fynya token, Letter of Credit (LC) or Fiat currency.
 <Link to="/fynya-pay"><span style={{marginLeft:"7px"}} >Learn more</span> <DoubleRightOutlined /></Link>
</p>
</Col>
</Row>
</p>

 


  </p>
  
</div>
</div>
 </motion.div>
 
 

</Col>




<Col md={12} xs={24} className={globalstyle.bgAboutPicture}> 
<img className={globalstyle.picture} src={fynyamarket} />
</Col>

</Row>

</div>

</div>





 */}

{/* 
 <div className={globalstyle.imageBgSteps}>
<div className={globalstyle.container}>

<div className={globalstyle.bodySection}>  

<div className={globalstyle.heading} style={{color:"#fff",paddingBottom:"9px"}}>Do you wish to buy commodities ?</div>
<div className={globalstyle.heading} style={{color:"#fff",paddingBottom:"29px"}}>Get Started In 3 Easy Steps</div>


<Row gutter={[24]}>
<Col md={12} xs={24}> 

<p className={modules.descriptionWhite}>
<div className={globalstyle.subHeading}>Create Account</div>

<Row gutter={[24]}>
<Col md={3} xs={4}>
<div className={globalstyle.stepNumber}>
1
</div>
</Col>
<Col md={21} xs={20}>
<p className={globalstyle.stepNumberText}>
Create a new account by filling out the application form.
</p>
</Col>
</Row>

<div className={globalstyle.subHeading}>Browse commodities</div>

<Row gutter={[24]}>
<Col md={3} xs={4}>
<div className={globalstyle.stepNumber}>
2
</div>
</Col>
<Col md={21} xs={20}>
<p className={globalstyle.stepNumberText}>
Browse wide variety of commodities listed by different commodity traders.
</p>
</Col>
</Row>

<div className={globalstyle.subHeading}>Select Payment Method</div>

<Row gutter={[24]}>
<Col md={3} xs={4}>
<div className={globalstyle.stepNumber}>
3
</div>
</Col>
<Col md={21} xs={20}>
<p className={globalstyle.stepNumberText}>
Wide selection of payment methods. Pay using Fynya token, Letter of Credit (LC) or Fiat currency.
 <Link to="/fynya-pay"><span style={{marginLeft:"7px"}} >Learn more</span> <DoubleRightOutlined /></Link>
</p>
</Col>
</Row>
</p>

 


 
</Col>

<Col md={12} xs={24}> 
 

</Col>
 
</Row>
 
<Row gutter={[24]}>
<Col md={9} sm={9}  xs={0}>
</Col>
<Col md={6} sm={6} xs={24}> 

<Link to="/contact"><div className={globalstyle.menuButton} style={{marginTop:"30px"}}> Get Started</div></Link>
</Col>
</Row>

</div>
</div>
</div>
  */}







{/* <div style={{backgroundColor:"rgba(91, 43, 188, 1)"}}></div> */}
<div style={{backgroundColor:"#fff"}}>
<div className={globalstyle.container}>


<ProductsSlider />

</div>
</div>
<RequestDemo />
</div>
    );
};

export default FynyaMarket;