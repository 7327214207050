import React from 'react';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import { Col, Row } from 'antd';
import { FileProtectOutlined, BankOutlined} from '@ant-design/icons';
import modules from './modules.module.css';
import globalstyle from '../../style.module.css';
import lcs from './lcs.png';
import fynyamarket2 from './fynyamarket2.jpg';
import products from './products.module.css';
import RequestDemo from '../requestDemo/requestDemo';
 
 

const FynyaDocs = () => {
return (
<div>
<MetaTags>
            <title>Fynya | Blockchain Powered LCs</title>
            <meta name="description" content="A module for issuance, verification and validation of digital letters of credit." />
            <meta property="og:title" content="Fynya | Blockchain Powered LCs" />
            <meta property="og:image" content="../../assets/img/logo.png" />
</MetaTags>
<div className={globalstyle.mainPageBannerImageBg}>      
<div className={globalstyle.imageBg}>      
<div className={globalstyle.openingText}>
<span className={globalstyle.openingTextHeader}>  Blockchain LCs. </span>

   <br />
     <span className={globalstyle.openingTextDescription} style={{padding:"20px"}}>Blockchain Powered LCs
</span> 
        <br/>
 
 
 
</div>
</div>


</div>

 
<div className={globalstyle.container}>
 


 <div className={globalstyle.bodySection}>            
 <div className={globalstyle.bodySectionRow}>            
<Row gutter={[24]}>
<Col md={12} xs={24} className={globalstyle.bgAboutPicture}> 
<img className={globalstyle.picture} src={lcs} />
</Col>
<Col md={12} xs={24}> 
<div>
{/* <span className={globalstyle.mainHeading}>Vision</span> */}
<p className={modules.description}>
A module for issuance, verification and validation of digital letters of credit. 
Commodity houses and other players can submit, view digital trade data and documents concurrently therefore eliminating the need for endless data input, reconciliation, and verification while injecting better visibility and fraud protection into the trade financing process.
<Col md={12} sm={12} xs={24}> 

<Link to="/contact"><div className={globalstyle.menuButton} style={{marginTop:"30px"}}> Request Demo</div></Link>
</Col>
</p>



</div>
</Col>

</Row>
</div>

</div>
 
</div>


<div style={{backgroundColor:"#271D58"}}>
<div className={globalstyle.container}>

<div className={globalstyle.bodySection}>            
        <Row gutter={[24]}>
<Col md={12} xs={24}> 

<p className={modules.descriptionWhite}>
<div className={globalstyle.heading} style={{color:"#fff",paddingBottom:"29px", textAlign:"left"}}>Why digitize LCs</div>

Digitized letter of credit (LC) transaction bring a notable reduction in the processing 
time compared to traditional methods. 


With the help of blockchain, exporters, importers and their respective banks can share information on a private distributed ledger. 
The trade deal is executed automatically through a series of digital smart contracts which are executed 
automatically upon satisfaction of certain conditions. The parties involved in the 
transaction can visualise data in real time and see the next actions to be performed.
 This leads to a single, 
immutable record of a trade verified by all parties.



</p>


</Col>
<Col md={12} sm={12} xs={24} className={globalstyle.bgAboutPicture}> 
<div className={modules.aboutImage}>
<img className={globalstyle.picture} src={fynyamarket2} />
 </div>
 </Col>
</Row>
 
</div>
</div>
</div>


<div className={globalstyle.container}>

<div className={globalstyle.bodySection}>
 
<div className={globalstyle.heading} style={{paddingBottom:"29px"}}>Benefits</div>

  
<Row gutter={[24]}>

<Col md={6} sm={12} xs={24}> 

 <div className={products.advantage}>      
<span className={products.productIcon}><FileProtectOutlined /></span>
<div className={globalstyle.heading}>Fraud Reduction</div>
  <p style={{textAlign:"center"}}>
  Capabilities around transparency and consensus will help mitigate the ever-present risk of documentary fraud and also reduce the 
  cost of transaction reconciliation between parties</p>
</div>
   </Col>

<Col md={6} sm={12} xs={24}> 
 
 <div className={products.advantage}>      
<span className={products.productIcon}><FileProtectOutlined /></span>
<div className={globalstyle.heading}>Traceability</div>
<p style={{textAlign:"center"}}>
The traceability associated with blockchain provides assurance and authenticity of products in the supply chain
</p>
 </div>
</Col>

<Col md={6} sm={12} xs={24}> 
 <div className={products.advantage}>      
<span className={products.productIcon}><BankOutlined /></span>
<div className={globalstyle.heading}>Fast & Efficient</div>
<p style={{textAlign:"center"}}>
Single final version of the LC draft at any given time, all concerned parties are able to view and work on this version based on their access permissions</p>
</div>
 </Col>

<Col md={6} sm={12} xs={24}> 
 <div className={products.advantage}>      
<span className={products.productIcon}><FileProtectOutlined /></span>
<div className={globalstyle.heading}>Security</div>
<p style={{textAlign:"center"}}>
Improved security by reducing errors, make companies’ working capital more predictable and increase convenience for all
 parties through mobile interaction
 </p>
</div>
 </Col>

</Row>
 </div>
</div>




<div style={{backgroundColor:"#271D58"}}>
<div className={globalstyle.container}>

<div className={globalstyle.bodySection}>     

<div className={globalstyle.heading} style={{color:"#fff",paddingBottom:"29px"}}>How It Works</div>


        <Row gutter={[24]}>
        <Col md={8} xs={24}> 
<p className={modules.descriptionWhite}>
<div className={globalstyle.heading}>Importer</div>

<Row gutter={[24]}>
<Col md={4} xs={4}>
<div className={globalstyle.stepNumber}>
1
</div>
</Col>
<Col md={20} xs={20}>
<p className={globalstyle.stepNumberText}>
The importer and the exporter get into a trade agreement and
the importer creates an LC application for the importer bank to review and stores it on the blockchain network.
</p>
</Col>
</Row>
</p>

</Col>
<Col md={8} xs={24}> 
<p className={modules.descriptionWhite}>
<div className={globalstyle.heading}>Importer Bank</div>

<Row gutter={[24]}>
<Col md={4} xs={4}>
<div className={globalstyle.stepNumber}>
2
</div>
</Col>
<Col md={20} xs={20}>
<p className={globalstyle.stepNumberText}>
The importer bank receives notification to review the LC and can approve or reject it based on the data provided. Once the LC has been checked and approved, 
access is then granted to the exporter bank for approval.
</p>
</Col>
</Row>
</p>

</Col>
<Col md={8} xs={24}> 
<p className={modules.descriptionWhite}>
<div className={globalstyle.heading}>Exporter Bank</div>

<Row gutter={[24]}>
<Col md={4} xs={4}>
<div className={globalstyle.stepNumber}>
3
</div>
</Col>
<Col md={20} xs={20}>
<p className={globalstyle.stepNumberText}>

The exporter bank approves or rejects the Letter of Credit. 
If approved, the exporter is able to view the LC requirements.
  
</p>
</Col>
</Row>
</p>

</Col>
 
</Row>






<Row gutter={[24]}>
        <Col md={8} xs={24}> 
<p className={modules.descriptionWhite}>
<div className={globalstyle.heading}>Exporter and Shipment</div>

<Row gutter={[24]}>
<Col md={4} xs={4}>
<div className={globalstyle.stepNumber}>
4
</div>
</Col>
<Col md={20} xs={20}>
<p className={globalstyle.stepNumberText}>
The exporter completes the shipment, adds invoice and export application data and attaches a photo image of any other required documents. 
Once validated, these documents are stored on the blockchain.
</p>
</Col>
</Row>
</p>

</Col>
<Col md={8} xs={24}> 
<p className={modules.descriptionWhite}>
<div className={globalstyle.heading}>Approval/Rejection</div>

<Row gutter={[24]}>
<Col md={4} xs={4}>
<div className={globalstyle.stepNumber}>
5
</div>
</Col>
<Col md={20} xs={20}>
<p className={globalstyle.stepNumberText}>
The documents are viewed by the exporter bank, which approves or rejects the application.</p>
</Col>
</Row>
</p>

</Col>
<Col md={8} xs={24}> 
<p className={modules.descriptionWhite}>
<div className={globalstyle.heading}>LC Completion
</div>

<Row gutter={[24]}>
<Col md={4} xs={4}>
<div className={globalstyle.stepNumber}>
6</div>
</Col>
<Col md={20} xs={20}>
<p className={globalstyle.stepNumberText}>
The importer bank reviews the data and images against the LC requirements, marking any discrepancies for review by the importer. 
When approved, the LC goes straight to completed status.
</p>
</Col>
</Row>
</p>

</Col>
 
</Row>
 
</div>
</div>
</div>






<RequestDemo />

</div>
    );
};

export default FynyaDocs;