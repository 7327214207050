import React from 'react';
import { Col, Row } from 'antd';
import Zoom from 'react-reveal/Zoom';
import { SafetyCertificateOutlined, ThunderboltOutlined, ArrowUpOutlined, CheckCircleOutlined } from '@ant-design/icons';
import homeStyle from './homeStyle.module.css';
import globalstyle from '../../style.module.css';
import smartc from '../../assets/img/smartc.png';
import platforms2a from '../../assets/img/platform2a.png';
import pt3 from '../../assets/img/pt3.png';
import pt4 from '../../assets/img/pt4.png';
import pt5 from '../../assets/img/pt5.png';
const PlatformFeatures = ({ reference }) => {
  const items = [
    {item:"Parties involved in a transaction to interact via a single platform    "},
    {item:"Real-time data exchange, reducing costs for participants"},
    {item:"Secure sharing of data directly between the relevant parties"},
    {item:"Authenticate parties and record transactions to reduce fraud"}
  ]
return (
<div className={homeStyle.whyBlockchain}>
<div className={globalstyle.container}>
<div className={homeStyle.whyBlockchainSection}>

<Zoom>
<div className={globalstyle.featureSection}>
<div className={homeStyle.openingTextHeading} style={{color:"#fff",marginLeft:"15px",marginBottom:"15px"}}> Value Proposition. </div>
<br/>
 <Row gutter={[24]}>

<Col md={12} sm={12} xs={0}> 
 
<div className={homeStyle.whyBc}>      
<img className={homeStyle.roundImage} src={pt4} />
</div>
 </Col>

<Col md={12} sm={12} xs={24}> 
 
<div className={homeStyle.whyBc}>      
<div className={globalstyle.blockText}>  
<div className={globalstyle.smallColorHeading} style={{color:"#FF4094"}}>TRACEABILITY</div>
<div className={globalstyle.bigHeading}> Transparency and traceability.</div>

<p>
Capabilities around transparency and consensus will help mitigate the ever-present risk of documentary 
fraud and also reduce the cost of transaction reconciliation between parties.
{/* 

Complete transaction traceability, proven track record of executed transactions.
The traceability associated with blockchain provides assurance and
authenticity of products in the supply chain. */}
</p>
</div>
</div>
 </Col>

</Row>
 
</div>

<div className={globalstyle.featureSection}>
 <Row gutter={[24]}>

<Col md={12} sm={12} xs={24}> 
<div className={homeStyle.whyBc}>      
<div className={globalstyle.blockText}>      
<div className={globalstyle.smallColorHeading} style={{color:"#09ce8b"}}>EFFICIENT</div>
<div className={globalstyle.bigHeading}> 
Fast and efficient
{/* Instantaneous Transactions. */}
</div>
<p>
Single final version of the LC draft at any given time in which all concerned
parties are able to view and work depending on access permissions.
{/* 
As a result of Ethereum blockchain, transactions are almost instantaneous, with a
median waiting time of 27 seconds.
Transactions are executed on a peer-to-peer basis without a centralized clearing house. */}
</p>
</div>
</div>
</Col>

<Col md={12} sm={12} xs={0}> 
<div className={homeStyle.whyBc}>      
<img className={homeStyle.roundImage} src={pt3} />
</div>
</Col>

</Row>
 
</div>



<div className={globalstyle.featureSection}>



 <Row gutter={[24]}>

 <Col md={12} sm={12} xs={0}> 
<div className={homeStyle.whyBc}>      
<img src={platforms2a} className={globalstyle.imageShadow}/>
</div>
</Col>

<Col md={12} sm={12} xs={24}> 
<div className={homeStyle.whyBc}>      
<div className={globalstyle.blockText}>  
<div className={globalstyle.smallColorHeading} style={{color:"#0aa9c2"}}>SECURE</div>
<div className={globalstyle.bigHeading}><SafetyCertificateOutlined /> Secure Transactions.</div>

<p>
{/* Automated, secure and fast transactions with no middlemen and no bank fees, hence saving time and resources. */}

Platform is based on principles of cryptography, decentralization and consensus, which ensure trust in transactions.
Blockchain technology enables decentralization through the participation of members across a distributed network. 
There is no single point of failure and a single user cannot change the record of transactions. 
{/* 
Improved security by reducing errors, make companies’ working capital more
predictable and increase convenience for all parties through realtime interaction. */}
{/* Automated, secure and fast transactions with no middlemen and no bank fees, hence saving time and resources. */}
</p>
</div>
</div>
</Col>


</Row>
 
</div>

<div className={globalstyle.featureSection}>
 <Row gutter={[24]}>

<Col md={12} sm={12} xs={24}> 
<div className={homeStyle.whyBc}>      
<div className={globalstyle.blockText}>      
<div className={globalstyle.smallColorHeading} style={{color:"#09ce8b"}}>AUTO EXECUTION</div>
<div className={globalstyle.bigHeading}> Powered by smart contracts.
</div>
<p>

The agreements contained therein exist across a distributed, 
decentralized blockchain network. 
Smart contract execute a predefined set of terms
automatically in a
trackable and irreversible
manner without the
need for a third party.</p>
</div>
</div>
</Col>

<Col md={12} sm={12} xs={0}> 
<div className={homeStyle.whyBc}>      
<img src={smartc}  className={homeStyle.roundImage} />
</div>
</Col>

</Row>
 
</div>




<div className={globalstyle.featureSection}>



 <Row gutter={[24]}>

 <Col md={12} sm={12} xs={0}> 
<div className={homeStyle.whyBc}>      
<img src={pt5} className={homeStyle.roundImage}/>
</div>
</Col>

<Col md={12} sm={12} xs={24}> 
<div className={homeStyle.whyBc}>      
<div className={globalstyle.blockText}>  
<div className={globalstyle.smallColorHeading} style={{color:"#0aa9c2"}}>FAST SETTLEMENTS</div>
<div className={globalstyle.bigHeading}> International payments system.</div>

<p>
{/* Automated, secure and fast transactions with no middlemen and no bank fees, hence saving time and resources. */}

International payment system using Fynya token.
Fynya Token transactions can be completed in 30seconds - 1 minute, 
no international friction, resulting in seamless transactions,
efficient trading and customer satisfaction, 
unlike today's payments which take between 2-3 days.
{/* 
Improved security by reducing errors, make companies’ working capital more
predictable and increase convenience for all parties through realtime interaction. */}
{/* Automated, secure and fast transactions with no middlemen and no bank fees, hence saving time and resources. */}
</p>
</div>
</div>
</Col>


</Row>
 
</div>


</Zoom>


</div>
</div>
</div>
    );
};

export default PlatformFeatures;