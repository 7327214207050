import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { Col, Row } from 'antd';
import { UnorderedListOutlined, DoubleRightOutlined } from '@ant-design/icons';
import homeStyle from './homeStyle.module.css';
import globalstyle from '../../style.module.css';
import wheat from './products/wheat.jpg';
import rice from './products/rice.jpg';
import coffee from './products/coffee.jpg';
import cocoa from './products/cocoa.jpg';
import oil from './products/oil.jpg';
import energy from './products/energy.jpg';
import gold from './products/gold.jpg';
import livestock from './products/livestock.jpg';

const ProductsSlider = (props) => {
    const {deviceType} = props   

    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4,
          paritialVisibilityGutter: 90
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2,
          paritialVisibilityGutter: 50
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 2,
          paritialVisibilityGutter: 30
        }
      };

      const items = [
        {item:"Wheat",image:wheat},
        {item:"Rice",image:rice},
        {item:"Coffee",image:coffee},
        {item:"Livestock",image:livestock},
        {item:"Cocoa",image:cocoa},
        {item:"0il & Gas",image:oil},
        {item:"Energy",image:energy},
        {item:"Gold",image:gold}
      ]



return (
<>
<div className={globalstyle.bodySection}>

<div className={globalstyle.heading}>Commodities</div>


<Carousel       
       partialVisbile
       itemClass="image-item"
       autoPlay={true}
       deviceType={deviceType}
      responsive={responsive}
      swipeable={true}
  draggable={true}
  showDots={false}
  responsive={responsive}
  ssr={true} 
  infinite={true}
  keyBoardControl={true}
  customTransition="all .5"
  transitionDuration={500}
  containerClass="carousel-container"
  removeArrowOnDeviceType={["tablet", "mobile"]}
  dotListClass="custom-dot-list-style"
  itemClass="carousel-item-padding-40-px"
      >
{items.map((item, index) => 
<div key={index} className={homeStyle.sliderItem}>
{/* <img style={{width:"100%",borderRadius:"5px"}} src={item.image} />   */}
{/* <img src={require(`./products/${item.img}.jpg`)} alt={item.item}/> */}
{/* <img src={requestImageFile(`./${item.image}.jpg`)} alt={item.item}/> */}
{/* <DoubleRightOutlined />  */}
<div className={homeStyle.sliderNameBody}>
<div className={homeStyle.sliderName}>
{item.item}
</div>
</div>
</div>
 )}

</Carousel>

</div>
<>


 </>
 </>
    );
};

export default ProductsSlider;