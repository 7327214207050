import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {useForm} from 'react-hook-form';
import { Divider, Result, Form, Button } from 'antd';
import Web3 from 'web3';
import { CITIZENS_ADDRESS, CITIZENS_ABI } from '../../config/citizens'
import styles from './demo.module.css';
import globalstyle from '../../style.module.css';
const testnet = 'https://ropsten.etherscan.io/';

const demoInputs = [
  {
    type: "text",
    name: "name",
    label: "Full Name",
    required: true,
    value: "/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i",
    message: "Invalid Name"
 
  },
   {
     type: "number",
     name: "age",
     label: "Mobile Number",
     min : 18,
     max : 150,
     required: true,
     value: "/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i",
     message: "Invalid Age"
 
   },
   {
     type: "text",
     name: "city",
     label: "Email Address",
     required: true,
     value: "/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i",
     message: "Invalid City"
 
   }
]

const RequestDemoForm = (props) => {

  const web3Provider = useSelector(state=>state.Web3Provider);

const [loading, setLoading] = useState(false)
const [sucessMessage, setSucessMessage] = useState(false)
const [showForm, setShowForm] = useState(true)
const [noAccount, setNoAccount] = useState(false)


const { control, register, handleSubmit, errors } = useForm();
const ageError = errors.age && "Enter your name";



const onSubmit = async (data) => {
           setLoading(true);
            const {age, city, name, someNote} = data;
            const web3 = new Web3(Web3.givenProvider || testnet)
            const citizensList = new web3.eth.Contract(CITIZENS_ABI, CITIZENS_ADDRESS)
            const accounts = await web3.eth.getAccounts()

            try {
              await citizensList.methods.addCitizen(age, city, name, someNote).send({ from: accounts[0] })
            .once('receipt', (receipt) => {
              setSucessMessage(true);
              setLoading(false);
              setShowForm(false);     
          })
            } catch (e) {
              setNoAccount(true);
              setLoading(false);
          } finally {
              console.log('We do cleanup here');
          }


}      
 
return (
<div className={styles.loginForm}>

 <>
<Divider>Request Demo</Divider>


 <form  onSubmit={handleSubmit(onSubmit)}>
{ demoInputs.map((item, i) =>(
 <div key={i}>
<div className={styles.formLabel}>{item.label}</div>
    <Form.Item>
    {errors.name && errors.message}
<input
        type={item.type}
        name={item.name}
        style={{fontSize:"16px"}}
        className={styles.formText}
        ref={register({
          required: item.required,
          min: item.min,
          max: item.max,
          pattern: {
            value: item.value,
            message: item.message
          }
        })}
      />
    </Form.Item>
</div>
))
}
<span className={globalstyle.menuButton}>Submit</span>
{/* <Button  className={globalstyle.menuButton} >
Submit
</Button> */}
</form>
 
</>
</div>
 );
};

export default RequestDemoForm;