import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import LogoBar from './components/header/logoBar';
import Footer from './components/footer/footer';
// import Topbar from './components/header/topbar';
import Home from './components/home/home';
import Login from './components/auth/login';
// import AuthVerify from './containers/auth/authVerify';
// import Web3 from 'web3'

// import { Web3Provider } from './actions/actions';
import FynyaPay from './components/products/fynyaPay';
import FynyaDocs from './components/products/fynyaDocs';
import FynyaMarket from './components/products/fynyaMarket';
import FynyaKyc from './components/products/fynyaKyc';
import Contact from './components/pages/contact';
import Terms from './components/pages/terms';
import Privacy from './components/pages/privacy';
import ScrollToTop from './scrollToTop';
import Team from './components/pages/team';
import CommodityTrade from './components/products/commodityTrade';
import About from './components/home/about';
import Roadmap from './components/pages/roadmap';
import Timeline from './components/pages/timeline';


function App(props) {

// const dispatch = useDispatch();   

const showHeaderState = useSelector(state=>state.ShowHeader);

// dispatch(Web3Provider(Web3.givenProvider))

return (
<Router>
{ showHeaderState ?
<>
{/* <Topbar/> */}
<LogoBar />

</>
:
null
}
<ScrollToTop />
<Switch>
<Route exact path="/" component={Home} />
<Route path="/fynya-pay"  component={FynyaPay} /> 
<Route path="/commodity-trade"  component={CommodityTrade} /> 
<Route path="/fynya-market"  component={FynyaMarket} /> 
<Route path="/blockchain-letter-of-credit"  component={FynyaDocs} /> 
<Route path="/kyc-module"  component={FynyaKyc} /> 
<Route path="/roadmap"  component={Roadmap} /> 
<Route path="/timelime"  component={Timeline} /> 
<Route path="/terms"  component={Terms} /> 
<Route path="/privacy"  component={Privacy} /> 
<Route path="/contact"  component={Contact} /> 
<Route path="/about"  component={About} /> 
<Route path="/team"  component={Team} /> 
<Route path="/login"  component={Login} /> 
</Switch>

{ showHeaderState ?
<Footer />
:
null
}
</Router>
);
}

export default App;
