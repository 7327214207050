import React, { useRef } from 'react';
import Zoom from 'react-reveal/Zoom';
import globalstyle from '../../style.module.css';
import HeaderStatus from '../../containers/settings/headerStatus';
// import Slider from '../slider/slider'
// import Products from '../products/products';
import HomeMain from './homeMain';
// import Blockchain from './blockchain';
import RequestDemo from '../requestDemo/requestDemo';
// import Players from './players';
// import ProductsSlider from './productsSlider';
// import TargetMarket from './targetMarket';
import PlatformFeatures from './platformFeatures';
// import PrimaryHome from './primaryHome';
import Features from '../products/features';
import SeperatorWave from '../wave/wave';
import Intro from './intro';
  
  
function Home() {

HeaderStatus(true) ;

const scrollToDiv = (ref) => window.scrollTo(0, 1200);
const el1 = useRef();
const el2 = useRef();
      
return (
<div className={globalstyle.bodyBg}>

<HomeMain reference={el1} click={()=> scrollToDiv(el2)}/>
<SeperatorWave />
<div className={globalstyle.container}>
<Intro />
<Zoom>  
</Zoom>
 </div>
 <SeperatorWave />
 <div>
 <div className={globalstyle.container}>
 <Features />
 </div>
 <SeperatorWave />
<PlatformFeatures />
</div>
<div className={globalstyle.container}>
</div>
<RequestDemo />
</div>
);
}

export default Home;
