import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Typist from 'react-typist';
import { Col, Row, Divider, Result, Form, Button } from 'antd';
import { DownCircleOutlined } from '@ant-design/icons';
import Fade from 'react-reveal/Fade';

import {useForm} from 'react-hook-form';
 import homeStyle from './homeStyle.module.css';
import globalstyle from '../../style.module.css';
import MetaTags from 'react-meta-tags';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

const demoInputs = [
  {
     type: "number",
     name: "age",
     label: "Mobile Number",
     min : 18,
     max : 150,
     required: true,
     value: "/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i",
     message: "Invalid Age"
 
   }
  ]

const HomeMain = ({ reference, click }) => {

  const [value, setValue] = useState()

  const { control, register, handleSubmit, errors } = useForm();
  const ageError = errors.age && "Enter your name";

const onSubmit = async (data) => {
   const {age, city, name, someNote} = data;
}  
  

return (
<>

<MetaTags>
            <title>Fynya | Commodity Trade Finance Simplified.</title>
            <meta name="description" content="Fynya | Commodity Trade Finance Simplified." />
            <meta property="og:title" content="Fynya | Commodity Trade Finance Simplified." />
            <meta property="og:image" content="../../assets/img/logo.png" />
</MetaTags>

<div className={homeStyle.homeMain}>    
<div className={globalstyle.container}>
<Fade left>
<div className={homeStyle.openingText}>
<div className={homeStyle.openingTextHeader}> Hello Freedom. </div>

  {/* <Typist>

  <br />
     <span className={homeStyle.openingTextDescription}>Commodity Trade Finance made fast,<br/> simple and secure.</span> 
        <br/>
</Typist> */}
<Col md={8} sm={8} xs={24}> 
<div className={homeStyle.openingTextDescription}>Commodity Trade Finance made fast, simple and secure.</div> 
</Col>
 <Col md={15} sm={15} xs={24}> 

<p className={homeStyle.openingDescription}>
Fynya is envisioned to facilitate world trade seamlessly by use of
cutting edge technology to influence world trade in a fundamentally disruptive manner,
creating an environment for empowering people through trade and break
existing barriers to promote growth and prosperity.
</p>

</Col>
<Row gutter={[8,8]}>

<Col md={4} sm={4} xs={24}> 
<Link to="/about"><div className={globalstyle.menuButton}> About us</div></Link>
</Col>
<Col md={1} sm={1} xs={0}> 
</Col>
<Col md={4} sm={4} xs={24}> 
<Link to="/contact"><div className={globalstyle.menuButtonOutline}> Book a demo</div></Link>
</Col>
</Row>
  {/* <span className={homeStyle.openingTextBackspace}> Eliminating inefficiencies, reduction in operational complexity and transaction costs</span> */}

        {/* <p className={homeStyle.openingTextDescriptionSmall}>An all-in-one blockchain powered marketplace platform to facilitate commodity trade finance</p> */}
{/* <div className={homeStyle.scrollDownArrow}> 
       <DownOutlined style={{fontSize:"60px"}} />
</div> */}
</div>
</Fade>

<div className={globalstyle.container}>      



{/* <div className={homeStyle.requestDemoDashboard}>  
<div  style={{fontSize:"15px",textAlign:"center",marginBottom:"15px"}}>Book a demo and begin your trading freedom.</div>
<div style={{backgroundColor:"#FFF",padding:"0 10px",borderRadius:"5px"}}>  

<Row gutter={[8,8]}>

<Col md={16} xs={14}> 

<PhoneInput
      placeholder="Mobile number ..."
      value={value}
      className={homeStyle.reqForm}
      style={{border:"0px"}}
      defaultCountry="AE"
      onChange={setValue}/>

</Col>
<Col md={8} xs={10}> 
<span className={globalstyle.menuButton} style={{fontSize:"12px"}}> Test drive fynya</span>
</Col>
</Row>
</div>
</div> */}

 {/* 
<div style={{textAlign:"center"}} ref={reference}>
<div className={homeStyle.waitingForConnection} onClick={click}><DownCircleOutlined style={{color:"#fff",fontSize:"50px",fontWeight:"300",cursor:"pointer"}} /></div>
</div> */}
 </div>
 </div>
 </div>

</>
);
};

export default HomeMain;