import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
import { Col, Row } from 'antd';
import { SlidersOutlined, FileProtectOutlined, SolutionOutlined, BankOutlined} from '@ant-design/icons';
import products from '../products/products.module.css';
import globalstyle from '../../style.module.css';
import platforms from '../../assets/img/platforms.png';

const Timeline = () => {
return (
  <div className={globalstyle.bodySection}>

{/* <span className={globalstyle.mainHeading}>Products</span> */}

<div className={products.productsSection}>



<Row gutter={[4, 4]}>

<Col md={12} sm={12} xs={24}> 
<Link to="/commodity-trade">
<motion.div
  initial={{ scale: 0 }}
  animate={{ rotate: 0, scale: 1 }}
  transition={{
    type: "spring",
    stiffness: 260,
    damping: 20
  }}
>
 <div className={products.product}>      
<div className={products.productItem}>      
{/* <span className={products.productIcon}><SlidersOutlined /></span> */}

  <p className={products.mainProduct}>
  <div className={globalstyle.heading} style={{textAlign:"left",marginBottom:"10px"}}>Commodity Trade Platform</div>
  Access and Trade wide range of commodities on our easy-to-use Commodity Trading Platform, built on powerful sophisticated technology and robust infrastructure, backed by an ecosystem of in-house tailor-made, custom built modules to increase yor commodity trading experience. 
  Choose from a wide variety of commodities, while benefiting from the latest real-time technology and available commodity prices.

  <Col md={12} sm={12} xs={24}> 

  <Link to="/commodity-trade"><div className={globalstyle.menuButton} style={{marginTop:"30px"}}> Start Trading Now</div></Link>
</Col>
  </p>
  
</div>
</div>
 </motion.div>
</Link>
</Col>




<Col md={12} xs={24} className={globalstyle.bgAboutPicture}> 
<img className={globalstyle.picture} src={platforms} />
</Col>

</Row>

</div>

<div className={products.productsList}>


<Row gutter={[4, 4]}>


<Col md={8} sm={12} xs={24}> 
<Link to="/fynya-market">
<motion.div
  initial={{ scale: 0 }}
  animate={{ rotate: 0, scale: 1 }}
  transition={{
    type: "spring",
    stiffness: 260,
    damping: 20
  }}
>
 <div className={products.product}>      
<div className={products.productItem}>      
<span className={products.productIcon}><SlidersOutlined /></span>
<div className={globalstyle.heading}>Fynya Marketplace</div>
  <p style={{textAlign:"center"}}>
  Connecting producers of the commodities directly with consumers across the world negating the need of middlemen and increasing profits for producers.
</p>
</div>
</div>
 </motion.div>
</Link>
</Col>

<Col md={8} sm={12} xs={24}> 
<Link to="/kyc-module">
<motion.div
  initial={{ scale: 0 }}
  animate={{ rotate: 0, scale: 1 }}
  transition={{
    type: "spring",
    stiffness: 260,
    damping: 20
  }}
>
  <div className={products.product}>      
<div className={products.productItem}>      
<span className={products.productIcon}><SolutionOutlined /></span>
<div className={globalstyle.heading}>KYC Module</div>
<span className={products.productDescription}>
  <p style={{textAlign:"center"}}>
Retain ownership of user data by  broadcasting to selected entities based on request, leading
 to faster, safer and more efficient KYC Verification.
</p>
</span>
</div>
</div>
</motion.div>
</Link>
</Col>

<Col md={8} sm={12} xs={24}> 
<Link to="/fynya-docs">
<motion.div
  initial={{ scale: 0 }}
  animate={{ rotate: 0, scale: 1 }}
  transition={{
    type: "spring",
    stiffness: 260,
    damping: 20
  }}
>
  <div className={products.product}>      
<div className={products.productItem}>      
<span className={products.productIcon}><FileProtectOutlined /></span>
<div className={globalstyle.heading}>Fynya Docs</div>
<p style={{textAlign:"center"}}>
Issuance, verification and validation of digital letters of credit, allowing commodity houses or other platforms to submit digital trade data and documents.
</p>
</div>
</div>
</motion.div>
</Link>
</Col>

</Row>
</div>
</div>
    );
};

export default Timeline;