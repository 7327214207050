import React, { useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import { Drawer, Form, Button, Col, Row, Input, Select, DatePicker, Divider, Menu, Dropdown } from 'antd';
import { FileOutlined, AppstoreFilled, DownOutlined, RightCircleOutlined } from '@ant-design/icons';


import globalstyle from '../../style.module.css';
import styles from './header.module.css';
import logo from '../../assets/img/logoenv.png'
import ScreenSize from '../settings/screenSize';

const { Option } = Select;

const ddmenu = (
  <Menu>
    <Menu.Item>
    <Link to="/blockchain-letter-of-credit">Blockchain LCs</Link>   
     </Menu.Item>
    <Menu.Item>
    <Link to="/fynya-market">Tokenised Marketplace </Link>    
    </Menu.Item>
     <Menu.Item>
     <Link to="/kyc-module">Blockchain KYC </Link>
      </Menu.Item>
      {/* <Menu.Item>
    <Link to="/commodity-trade">Commodity Trade </Link>
    </Menu.Item> */}
  </Menu>
);

const preventDefault = () => {
  preventDefault();
}

const LogoBar = () => {

const menu = [
  {
    title: "Fynya Market",
    description: "Commodity Trading Platform",
    route: "fynya-market"
  },
    {
    title: "Fynya Pay",
    description: "Commodity Trading Platform",
    route: "fynya-pay"
  },
    {
    title: "Fynya Docs",
    description: "Commodity Trading Platform",
    route: "fynya-docs"
  },
    {
    title: "KYC Module",
    description: "Commodity Trading Platform",
    route: "kyc-module"
  },

]  

const width = ScreenSize();

  const history = useHistory();

  const handleOnSubmit = () => {
    history.push(`/dashboard`);
  }

    const [visible, setVisible ] = useState(false)

    const showDrawer = () => {
        setVisible(true)
    };
  
    const onClose = () => {
        setVisible(false)
    };

return (
<>
 <Drawer
        //   title="Create a new account"
          width={340}
          onClose={onClose}
          visible={visible}
          bodyStyle={{ paddingBottom: 80 }}

          // footer={
          // <span className={globalstyle.menuButton} onClick={()=>{
          //        history.push(`/login`)
          //        setVisible(false)
          //   }}>Login</span>
          // }
        /*  footer={
            <div
              style={{
                textAlign: 'right',
              }}
            >
              <Button onClick={onClose} style={{ marginRight: 8 }}>
                Cancel
              </Button>
              <Button onClick={onClose} type="primary">
                Submit
              </Button>
            </div>
          }
          */
        >
        <Divider>Products</Divider>
            <div className={styles.productMenuItemBody}>

              { menu.map((menuItem)=>{
             <div className={styles.productMenuItem} onClick={() => {
                history.push(`/${menuItem.route}`)
                 setVisible(false)
              }}>
            <span>{menuItem.title}</span>
            <p>{menuItem.description}</p>
            </div>
              })
}

{/* 
<div className={styles.productMenuItem} onClick={()=>{
                 history.push(`/commodity-trade`)
                 setVisible(false)
            }}>
            <span>Commodity Trade</span>
            <p>Commodity Trade Finance Platform</p>
            </div> */}

                       
<div className={styles.productMenuItem} onClick={()=>{
                 history.push(`/blockchain-letter-of-credit`)
                 setVisible(false)
            }}>            
            <span>Blockchain Powered LCs</span>
            <p>
Issuance, verification and validation of digital LCs</p>
            </div> 

            <div className={styles.productMenuItem} onClick={()=>{
                 history.push(`/fynya-market`)
                 setVisible(false)
            }}>
            <span>Tokenised Marketplace</span>
            <p>Global Commodity Marketplace</p>
            </div>

  <div className={styles.productMenuItem} onClick={()=>{
                 history.push(`/kyc-module`)
                 setVisible(false)
            }}>  
            
            <span>Blockchain KYC </span>
            <p>
A decentralized platform to exchange documents on a secure, permission-based and private basis.</p>
            </div>

            {/* <div className={styles.productMenuItem} onClick={()=>{
                 history.push(`/fynya-pay`)
                 setVisible(false)
            }}> 
            <span>Fynya Pay</span>
            <p>
Instant transactions at low fees.</p>
            </div> */}
            </div>
</Drawer>

 <div className={styles.logoBar}>  
<div  className={globalstyle.container}>
<Row gutter={[24]}>
 <Col xs={11} sm={5} md={5}>
    <Link to="/"><img className={styles.logoBarLogo} src={logo} /></Link>
</Col>  
<Col xs={0} sm={19} md={19}>
<div  className={styles.menuItems}>
<div style={{marginRight:"25px"}} className={styles.menuStyle} >
<Dropdown placement="bottomCenter" overlay={ddmenu}>
    <a className="ant-dropdown-link" href="#" style={{fontSize:"20px",fontWeight:"400"}}>
      Products <DownOutlined />
    </a>
  </Dropdown>

  <Link to="/roadmap"><a href="#" style={{fontSize:"20px",fontWeight:"400",marginLeft:"24px",fontSize:"19px",borderRadius:"3px",padding:"12px",border:"1px solid #fff"}}>
      Roadmap 
    </a></Link>

</div>
{/* <div className={styles.menuStyle} ><Link to="/commodity-trade">Commodity Trade </Link></div> 
<div className={styles.menuStyle} ><Link to="/fynya-market">Global Marketplace </Link></div> 
<div className={styles.menuStyle} ><Link to="/kyc-module">Fynya Docs</Link></div> 
<div className={styles.menuStyle} ><Link to="/kyc-module">Blockchain KYC </Link></div>  */}
<Link to="/contact"><span className={globalstyle.menuButton} style={{marginTop:"-10px"}}>Get Started</span></Link> 

 </div>
</Col>
{/* <Col xs={0} sm={9} md={3}>
 <a href="#"><span className={globalstyle.menuButton}>Whitepaper <FileOutlined /></span></a> 
</Col> */}
<Col xs={13} sm={0} md={0}>

<span className={globalstyle.mousePointer}>
    { (width > 700) ? 
  <span className={globalstyle.menuButton} onClick={showDrawer}> Products <AppstoreFilled /></span>
  :
  <>
  {/* <span>
   <Link to="/roadmap"><a href="#" style={{fontSize:"20px",fontWeight:"400",fontSize:"19px",margin:"15px 5px",borderRadius:"3px",color:"#fff",padding:"12px",border:"1px solid #fff"}}>
      Roadmap 
    </a></Link>
    </span> */}
     <Link to="/roadmap"><span className={globalstyle.mobileMenuButtonOutline}> Roadmap</span></Link>
    <span className={globalstyle.mobileMenuButton} onClick={showDrawer} style={{marginLeft:"18px"}}> <AppstoreFilled /></span>
  </>
} 
  </span> 


</Col>
</Row>
</div>
</div>
</>
);
};
 
export default LogoBar;
