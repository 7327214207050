import React, { useLayoutEffect, useState } from 'react';

const ScreenSize = () => {

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    


    return (
          width  
    );
};

export default ScreenSize;