import React from 'react';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import { Col, Row } from 'antd';
import { FileProtectOutlined, BankOutlined } from '@ant-design/icons';
import modules from './modules.module.css';
import globalstyle from '../../style.module.css';
import kyc from './fykyc.png';
import kyc3 from './kyc3.png';
import RequestDemo from '../requestDemo/requestDemo';
import products from './products.module.css'
import homeStyle from '../home/homeStyle.module.css';

const FynyaKyc = () => {
return (
<div>
<MetaTags>
            <title>Fynya | Blockchain Powered KYC</title>
            <meta name="description" content="Perfect platform to deliver an automated, secure, trustworthy KYC 
solution that improves the client experience, streamlines operational processes and enhances regulatory compliance.
" />
            <meta property="og:title" content="Fynya | Blockchain Powered KYC" />
            <meta property="og:image" content="../../assets/img/logo.png" />
</MetaTags>
<div className={globalstyle.imageBg}>      
<div className={globalstyle.openingText}>
<span className={globalstyle.openingTextHeader}> Fynya KYC. </span>

   <br />
     <span className={globalstyle.openingTextDescription} style={{padding:"20px"}}>
Blockchain Powered KYC 
</span> 
 
<br/>
 
 
 
</div>
</div>


<div className={globalstyle.container}>
<div className={globalstyle.bodySection}>



 <div className={globalstyle.bodySection}>            
 <Row gutter={[24]}>
<Col md={12} xs={24}> 
<div>
{/* <span className={globalstyle.mainHeading}>Vision</span> */}



<p className={modules.description}>

The platform deliver an automated, secure, trustworthy KYC 
solution that improves the client experience, streamlining operational processes and enhancing regulatory compliance.

Since data is stored on a single, universally accessible platform, 
KYC information can be shared through a user-controlled consent model resulting in operational savings and reduced operational risk. 

Unauthorized access is eliminated and users retain control and ownership of their data.

</p>

 
</div>
</Col>
<Col md={12} xs={24} className={globalstyle.bgAboutPicture}> 
<img className={globalstyle.picture} src={kyc} />
</Col>
</Row>
 
</div>

</div>
</div>





 <div className={globalstyle.imageBgSteps}>
<div className={globalstyle.container}>

<div className={globalstyle.bodySection}>  

{/* <div className={globalstyle.heading} style={{color:"#fff",paddingBottom:"9px"}}>Do you wish to buy commodities ?</div>
<div className={globalstyle.heading} style={{color:"#fff",paddingBottom:"29px"}}>Get Started In 3 Easy Steps</div> */}


<Row gutter={[24]}>


<Col md={12} xs={24}> 

{/* <span className={globalstyle.mainHeading}>Aim</span> */}

<p className={homeStyle.description}>

Fynya KYC leads to faster, safer and more efficient KYC Verification, benefitting both financial institutions and commodity traders. 
By speeding clearance and increasing trust, transactions can become more efficient globally.

Decrease in KYC administrative costs and processing time while at the same time reducing repetitive and redundant work and increasing KYC security and transparency.



 </p>




<Col md={6} sm={6} xs={24}> 

<Link to="/contact"><div className={globalstyle.menuButton} style={{marginTop:"30px"}}> Get Started</div></Link>
</Col>


</Col>

{/* <Col md={12} xs={24}> 

<p className={modules.descriptionWhite}>
<div className={globalstyle.subHeading}>Create Account</div>

<Row gutter={[24]}>
<Col md={3} xs={4}>
<div className={globalstyle.stepNumber}>
1
</div>
</Col>
<Col md={21} xs={20}>
<p className={globalstyle.stepNumberText}>
Create a new account by filling out the application form.
</p>
</Col>
</Row>

<div className={globalstyle.subHeading}>Browse commodities</div>

<Row gutter={[24]}>
<Col md={3} xs={4}>
<div className={globalstyle.stepNumber}>
2
</div>
</Col>
<Col md={21} xs={20}>
<p className={globalstyle.stepNumberText}>
Browse wide variety of commodities listed by different commodity traders.
</p>
</Col>
</Row>

<div className={globalstyle.subHeading}>Select Payment Method</div>

<Row gutter={[24]}>
<Col md={3} xs={4}>
<div className={globalstyle.stepNumber}>
3
</div>
</Col>
<Col md={21} xs={20}>
<p className={globalstyle.stepNumberText}>
Wide selection of payment methods. Pay using Fynya token, Letter of Credit (LC) or Fiat currency.
 <Link to="/fynya-pay"><span style={{marginLeft:"7px"}} >Learn more</span> <DoubleRightOutlined /></Link>
</p>
</Col>
</Row>
</p>

 


 
</Col> */}

 
</Row>


</div>
</div>
</div>



<div className={globalstyle.container}>
<div className={globalstyle.bodySection}>



 <div className={globalstyle.bodySection}>            
 <Row gutter={[24]}>
<Col md={12} xs={24}> 
<div>
{/* <span className={globalstyle.mainHeading}>Vision</span> */}
 

<p className={modules.description}>

KYC Updates would are done on the blockchain, meaning any entity using the system would also 
be privy to any information changes.<br/> Users can easily update their KYC across all their accounts 
 through their digital signatures.


{/* ** KYC workflow routing is coded into a smart contract and standardized across the industry, thereby streamlining the procedure. ** */}
{/* This will increase the effectiveness of the blockchain-based KYC system, as it would reduce the need for manual oversight. */}

 

</p>

 
</div>
</Col>
<Col md={3} xs={0}></Col>
<Col md={6} xs={0} className={globalstyle.bgAboutPicture}> 
<img className={globalstyle.picture} src={kyc3}/>
</Col>
</Row>

</div>

</div>
</div>





<div style={{backgroundColor:"#271D58"}}>
<div className={globalstyle.container}>

<div className={globalstyle.bodySection}>     

<div className={globalstyle.heading} style={{color:"#fff",paddingBottom:"29px"}}>The Process</div>


        <Row gutter={[24]}>
        <Col md={8} xs={24}> 
<p className={modules.descriptionWhite}>
<div className={globalstyle.heading}>Account Creation</div>

<Row gutter={[24]}>
<Col md={4} xs={4}>
<div className={globalstyle.stepNumber}>
1
</div>
</Col>
<Col md={20} xs={20}>
<p className={globalstyle.stepNumberText}>
Create a new account and upload documents for KYC procedure on our platform.
</p>
</Col>
</Row>
</p>

</Col>
<Col md={8} xs={24}> 
<p className={modules.descriptionWhite}>
<div className={globalstyle.heading}>Data Verification</div>

<Row gutter={[24]}>
<Col md={4} xs={4}>
<div className={globalstyle.stepNumber}>
2
</div>
</Col>
<Col md={20} xs={20}>
<p className={globalstyle.stepNumberText}>
Smart contract validate and verify your KYC and the data is written on the the blockchain.</p>
</Col>
</Row>
</p>

</Col>
<Col md={8} xs={24}> 
<p className={modules.descriptionWhite}>
<div className={globalstyle.heading}>KYC Sharing</div>

<Row gutter={[24]}>
<Col md={4} xs={4}>
<div className={globalstyle.stepNumber}>
3
</div>
</Col>
<Col md={20} xs={20}>
<p className={globalstyle.stepNumberText}>
Access range of instruments on our platform
 and benefit from low fees and advanced trading features.

</p>
</Col>
</Row>
</p>

</Col>
 
</Row>
<Row gutter={[24]}>
<Col md={9} sm={9}  xs={0}>
</Col>
<Col md={6} sm={6} xs={24}> 

<Link to="/contact"><div className={globalstyle.menuButton} style={{marginTop:"30px"}}> Get Started</div></Link>
</Col>
</Row>
</div>
</div>
</div>


 



<div className={globalstyle.container}>

<div className={globalstyle.bodySection}>
 
<div className={globalstyle.heading} style={{paddingBottom:"29px"}}>Benefits</div>

  
<Row gutter={[24]}>

<Col md={6} sm={12} xs={24}> 

 <div className={products.advantage}>      
<span className={products.productIcon}><FileProtectOutlined /></span>
<div className={globalstyle.heading}>Risk decentralization
</div>
  <p style={{textAlign:"center"}}>
  Capabilities around transparency and consensus will help mitigate the ever-present risk of documentary fraud and also reduce the 
  cost of transaction reconciliation between parties</p>
</div>
   </Col>

<Col md={6} sm={12} xs={24}> 
 
 <div className={products.advantage}>      
<span className={products.productIcon}><FileProtectOutlined /></span>
<div className={globalstyle.heading}>Transparency</div>
<p style={{textAlign:"center"}}>
Efficient reporting and communication processes thereby saving time and money. 
Easy accessibility of reliable data, human error and fraud can be detected far more quickly.</p>
 </div>
</Col>

<Col md={6} sm={12} xs={24}> 
 <div className={products.advantage}>      
<span className={products.productIcon}><BankOutlined /></span>
<div className={globalstyle.heading}>Easy Verification</div>
<p style={{textAlign:"center"}}>
A cryptographic verification solution will help parties to quickly verify the identity of individuals. 
This is essential for data protection compliance as well as fraud prevention. </p>
</div>
 </Col>

<Col md={6} sm={12} xs={24}> 
 <div className={products.advantage}>      
<span className={products.productIcon}><FileProtectOutlined /></span>
<div className={globalstyle.heading}>Data quality</div>
<p style={{textAlign:"center"}}>
 User data is stored on a single, universally accessible platform and can only be accessed by authorised parties.
Data is secured by cryptography and cannot be altered without permission. Data has no errors, inaccuracies or inconsistencies </p>
</div>
 </Col>

</Row>

 </div>
</div>






<RequestDemo />
</div>
    );
};

export default FynyaKyc;