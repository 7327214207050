import React from 'react';
import { Col, Row, Space } from 'antd';
import homeStyle from './homeStyle.module.css';
import globalstyle from '../../style.module.css';
import aboutus from '../../assets/img/aboutus2.png';

import aboutImage2 from './about5.png';
import { Link } from 'react-router-dom';

const Intro = () => {
    return (

<div style={{paddingTop:"0px"}} className={globalstyle.blockJchainCubes}>            
<div className={globalstyle.container}>            
 <div className={globalstyle.bodySection}>    
{/* 
<Row gutter={[24]}>
<Col md={12} xs={24}> 
 <img className={globalstyle.picture} src={home} />
</Col>
</Row> */}




        <Row gutter={[24]}>
<Col md={12} xs={24}> 
<div className={homeStyle.openingTextHeading}  style={{color:"#fff",marginBottom:"15px"}}> Industry solutions. </div>

<span className={globalstyle.mainHeading}>Blockchain Powered LCs.</span>
<Link to="/blockchain-letter-of-credit">
<p className={homeStyle.description}>
Issuance, verification and validation of digital letters of credit in a synchronised manner therefore eliminating the need for endless data input, 
reconciliation, and verification while injecting better visibility and fraud protection into the trade financing process.
{/* Digitized letter of credit (LC) transaction bring a notable reduction in the processing time compared to traditional methods. */}
</p>
</Link>

 <span className={globalstyle.mainHeading}> Tokenised Marketplace.</span>
 <Link to="/fynya-market">
<p className={homeStyle.description}>
Simplification of International Commodity Trading Life Cycle 
by connecting producers with consumers from across the world, backed by an ecosystem of in-house custom built modules.</p>
</Link>

<span className={globalstyle.mainHeading}>Blockchain  KYC.</span><span style={{backgroundColor:"rgb(255,64,148)",color:"#fff",padding:"5px",marginLeft:"20px",borderRadius:"3px"}}>Coming soon</span>
<Link to="/kyc-module">
<p className={homeStyle.description}>
Automated, secure, trustworthy KYC solution that improves the client experience, streamlining operational processes 
and enhancing regulatory compliance resulting in operational savings and reduced operational risk.</p>
</Link>
</Col>
<Col md={12} sm={12} xs={0} className={globalstyle.bgAboutPicture}> 
<div className={homeStyle.aboutImage}>
 <img className={globalstyle.picture} style={{width:"250px",marginTop:"0px",float:"right"}} src={aboutus} />
 </div>
 </Col>
</Row>
 
</div>
</div>
</div>
    );
};

export default Intro;