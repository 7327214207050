import React, { useState } from 'react';
import cookieStyle from './cookieBar.module.css';
import globalstyle from '../../style.module.css'
import { Row, Col } from 'antd';
import { useCookies } from 'react-cookie'
import { Link } from 'react-router-dom';


const CookieBar = () => {

     const [cookies, setCookie, removeCookie] = useCookies(["acceptCookie"]);

     const setAcceptCookie = (value) => {
       setCookie("acceptCookie", value, { path: '/' });
     };
    
    return (
        <>
        <div className={cookieStyle.cookieBar}>
        <div className={globalstyle.container}>
            <Row gutter={[24]}>
            <Col md={20} xs={14}> 
            <div style={{paddingTop:"4px"}}><span>We use cookies to improve your experience on our website</span> </div> 
            </Col>
            <Col md={4} xs={10}> 
            <Link  onClick={() => setAcceptCookie("accepted")}><div className={globalstyle.borderButton}>Accept All Cookies</div></Link>
            </Col>
            </Row>
        </div>
        </div>

        </>
    );
};

export default CookieBar;