import React from 'react';
import { Col, Row, Space } from 'antd';
import homeStyle from './homeStyle.module.css';
import globalstyle from '../../style.module.css';
import aboutus from '../../assets/img/aboutus.png';

import aboutImage2 from './about5.png';

const About = () => {
    return (

<div style={{background:"#5B2BBC",paddingTop:"20px"}} className={globalstyle.blockJchainCubes}>            
<div className={globalstyle.container}>            
 <div className={globalstyle.bodySection}>    
{/* 
<Row gutter={[24]}>
<Col md={12} xs={24}> 
 <img className={globalstyle.picture} src={home} />
</Col>
</Row> */}




        <Row gutter={[24]}>
<Col md={12} xs={24}> 


<span className={globalstyle.mainHeading}>CEO's Message</span>
<div className={homeStyle.ceoMessageAbout}>  
<i>“Fynya aims at liberalising world trade by mapping demand and supply efficiently and managing supply chains and trade transactions.” Saeed Al Ghurair
</i>
</div> 


 <span className={globalstyle.mainHeading}>Vision</span>
<p className={homeStyle.description}>
    Fynya’s vision is to provide a solution to simplify the International Commodities Trading Lifecycle Management issues, protect investor money and boost user confidence by using the capability of Blockchain.
</p>
<span className={globalstyle.mainHeading}>Aim</span>

<p className={homeStyle.description}>
    Fynya aims to connect demand and supply side of commodity markets, connect producers of 
    commodities directly with consumers from across the world hence increasing transparency and 
    information dissemination between buyers and sellers. 
</p>

</Col>
<Col md={12} sm={12} xs={0} className={globalstyle.bgAboutPicture}> 
<div className={homeStyle.aboutImage}>
 <img className={globalstyle.picture} style={{width:"250px",marginTop:"60px",float:"right"}} src={aboutus} />
 </div>
 </Col>
</Row>
 
</div>
</div>
</div>
    );
};

export default About;