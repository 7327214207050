import React from 'react';
import MetaTags from 'react-meta-tags';
import { Col, Row } from 'antd';
import { FileProtectOutlined, BankOutlined} from '@ant-design/icons';
import pagesStyle from './pages.module.css';
import globalstyle from '../../style.module.css';
import team from './team2.jpg';

const Team = () => {
    return (
<>
<MetaTags>
            <title>Fynya | Team</title>
            <meta name="description" content="Fynya | Team" />
            <meta property="og:title" content="Fynya’s vision is to provide a solution to simplify the International Commodities Trading Lifecycle Management issues, protect investor money and boost user confidence by using the capability of Blockchain." />
            <meta property="og:image" content="../../assets/img/logo.png" />
</MetaTags> 
<img src={team} style={{width:"100%"}} />
 <div className={globalstyle.container}>            
 <div className={pagesStyle.contactsBody}>            
<Row gutter={[24]}>
<Col md={6} sm={6} xs={24}> </Col>
<Col md={12} sm={6} xs={24}> 
<div className={pagesStyle.contacts}>
<p className={pagesStyle.description}>
Our team comprise of Innovators with progressive minds, from different backgrounds, dedicated to building a platform that will disrupt commodity trade finance.
</p>

</div>



</Col>
<Col md={12} sm={0} xs={24} className={globalstyle.bgAboutPicture}> 
{/* <img className={globalstyle.picture} src={aboutImage2} /> */}

</Col>
</Row>




<div className={globalstyle.container}>

<div className={globalstyle.bodySection}>
 
{/* <div className={globalstyle.sectionHeader}>
    Products
</div> */}
{/* <p className={globalstyle.mainHeading}>Products</p> */}
<Row gutter={[24]}>

<Col md={6} sm={12} xs={24}> 

 <div className={globalstyle.advantage}>      
<span className={globalstyle.productIcon}><FileProtectOutlined /></span>
<div className={globalstyle.heading}>Growth-Oriented</div>
  <p style={{textAlign:"justify"}}>
</p>
</div>
   </Col>

<Col md={6} sm={12} xs={24}> 
 
 <div className={globalstyle.advantage}>      
<span className={globalstyle.productIcon}><FileProtectOutlined /></span>
<div className={globalstyle.heading}>Industry Experts</div>
<p style={{textAlign:"justify"}}>

</p>
 </div>
</Col>

<Col md={6} sm={12} xs={24}> 
 <div className={globalstyle.advantage}>      
<span className={globalstyle.productIcon}><BankOutlined /></span>
<div className={globalstyle.heading}>Ownership mindset</div>
<p style={{textAlign:"justify"}}>
</p>
</div>
 </Col>

<Col md={6} sm={12} xs={24}> 
 <div className={globalstyle.advantage}>      
<span className={globalstyle.productIcon}><FileProtectOutlined /></span>
<div className={globalstyle.heading}>Dedicated</div>
<p style={{textAlign:"justify"}}>
</p>
</div>
 </Col>

</Row>
 </div>
</div>





</div>

</div>

</>
    );
};

export default Team;