import React from 'react';
import { motion } from "framer-motion";
import { Col, Row } from 'antd';
import Zoom from 'react-reveal/Zoom';
import MetaTags from 'react-meta-tags';
import { ClockCircleOutlined } from '@ant-design/icons';

 import homeStyle from '../home/homeStyle.module.css';
import globalstyle from '../../style.module.css';
import blockchain from '../../assets/img/blockchain.png';
import pl from '../../assets/img/pl.png';
import tr from '../../assets/img/tr.png';
import RequestDemo from '../requestDemo/requestDemo';


const Roadmap = ({ reference }) => {
 
  const items = [
    {item:"Parties involved in a transaction to interact via a single platform    "},
    {item:"Real-time data exchange, reducing costs for participants"},
    {item:"Secure sharing of data directly between the relevant parties"},
    {item:"Authenticate parties and record transactions to reduce fraud"}
  ]


return (
<>
<MetaTags>
            <title>Fynya | Global Marketplace</title>
            <meta name="description" content="Become a part of the global commodities marketplace quickly and easily.
" />
            <meta property="og:title" content="Fynya | Global Marketplace" />
            <meta property="og:image" content="../../assets/img/logo.png" />
</MetaTags>

<br/>
<br/>
<br/>

<div className={homeStyle.whyBlockchain}>
<div className={globalstyle.container}>
<div className={homeStyle.whyBlockchainSection}>

<Zoom>
{/* <div className={globalstyle.timelineSection}>
 <Row gutter={[24]}>
 <Col md={6} sm={6} xs={0}> 
</Col>
<Col md={12} sm={12} xs={22}> 
<div className={homeStyle.darkBgShort}>      
<div className={globalstyle.blockText}>  
<p>
Transparency and openness are our key values. Therefore, we will follow a lean and agile approach, developing Fynya iteratively, with 
thorough testing at all milestones and actively implementing community feedback.</p>
</div>
</div>
 </Col>
</Row>
</div> */}
<div className={globalstyle.timelineSection}>
 <Row gutter={[24]}>
 <Col md={3} sm={3} xs={0}> 
</Col>
 <Col md={3} sm={3} xs={2}> 
<div className={homeStyle.vLine}>      
{/* <img src={pl} /> */}
</div>
</Col>
<Col md={12} sm={12} xs={22}> 
<div className={homeStyle.darkBg}>      
<div className={globalstyle.blockText}>      
<div className={globalstyle.smallColorHeading} style={{color:"#09ce8b"}}>August 2019</div>
<div className={globalstyle.bigHeading}>Initial industry engagement.
</div>
<p>
After series of engagements with the captains of the industry, problem to the existing flow was pointed out. 
</p>
<div className={globalstyle.smallHeading}>Problem.</div>
<p>
Commodity Trade Finance is characterized by inefficiency in
business operations and logistics.

The industry still labor to catch up with the technological
progresses driven by our digital age.

Payment settlement methods using letters of credit and the
associated documentation have for long provided effective
risk mitigation for trading partners through bank facilitation in
the process.


</p>
</div>
</div>
</Col>
</Row>
</div>




<div className={globalstyle.timelineSection}>
 <Row gutter={[24]}>
 <Col md={3} sm={3} xs={0}> 
</Col>
 <Col md={3} sm={3} xs={2}> 
<div className={homeStyle.vLine}>      
</div>
</Col>
<Col md={12} sm={12} xs={22}> 
<div className={homeStyle.darkBg}>      
<div className={globalstyle.blockText}>  
<div className={globalstyle.smallColorHeading} style={{color:"#0aa9c2"}}><ClockCircleOutlined /> December 2019</div>
<div className={globalstyle.bigHeading}>Research and development</div>



<p>
After thorough research and development, challenges were compiled. 
</p>
<div className={globalstyle.smallHeading}>Challenges.</div>

<ul>
<li>
Complex and slow clearing and settlement
</li>
<li>High transaction costs
</li>
<li>
Time and cost associated with LC issuance, verification
and validation
</li>
<li>
Middlemen who often tap into producer profits
</li>
<li>
Lack of trust between trading parties
</li>
<li>
Lack of Clear Audit Trails
</li>
<li>
Bulk documentation involved in the process, which often
leads to transactions taking ages
</li>
<li>
LCs are prone to errors, data mismatch in documents
which may lead to delay in payments
</li>
<li>

Fraud and counterfeiting associated with LCs
</li>
</ul>

</div>
</div>
</Col>
</Row>
</div>



 


<div className={globalstyle.timelineSection}>
 <Row gutter={[24]}>
 <Col md={3} sm={3} xs={0}> 
</Col>
 <Col md={3} sm={3} xs={2}> 
<div className={homeStyle.vLine}>      
</div>
</Col>
<Col md={12} sm={12} xs={22}> 
<div className={homeStyle.darkBg}>      
<div className={globalstyle.blockText}>  
<div className={globalstyle.smallColorHeading} style={{color:"#0aa9c2"}}><ClockCircleOutlined /> February 2020</div>
<div className={globalstyle.bigHeading}>Birth of the idea</div>
<div className={globalstyle.smallHeading}>Solution.</div>

<ul>
<li>
An all-in-one smart contract powered marketplace platform to
facilitate commodity trade finance.
</li>
<li>
A decentralized, permission-based network to exchange, issue,
validate and verify trade documents.
</li>
<li>
A decentralised KYC which ensures that user data can be accessed
by third parties after permission has been given by the user.
</li>
<li>
Reduction in Operating Cost: Smart inventory management
through self-executing contracts, is a step ahead phenomenon
above paperless model, minimizes operating budgets drastically
</li>
</ul>
</div>
</div>
</Col>
</Row>
</div>


<div className={globalstyle.timelineSection}>
 <Row gutter={[24]}>
 <Col md={3} sm={3} xs={0}> 
</Col>
 <Col md={3} sm={3} xs={2}> 
<div className={homeStyle.vLineShort}>      
{/* <img src={pl} /> */}
</div>
</Col>
<Col md={12} sm={12} xs={22}> 
<div className={homeStyle.darkBgShort}>      
<div className={globalstyle.blockText}>      
<div className={globalstyle.smallColorHeading} style={{color:"#09ce8b"}}>June 2020</div>
<div className={globalstyle.bigHeading}>Design and Development.
</div>
{/* <p>
Technology stack selected.
</p> */}
</div>
</div>
</Col>
</Row>
</div>

<div className={globalstyle.timelineSection}>
 <Row gutter={[24]}>

 <Col md={3} sm={3} xs={0}> 
</Col>

 <Col md={3} sm={3} xs={2}> 
 
 <div className={homeStyle.vLineShort}>      
 {/* <img src={tr} /> */}
 </div>
  </Col>
  <Col md={12} sm={12} xs={22}> 
 
<div className={homeStyle.darkBgShort}>      
<div className={globalstyle.blockText}>  
<div className={globalstyle.smallColorHeading} style={{color:"#FF4094"}}>February 2021</div>
<div className={globalstyle.bigHeading}>POC Released.</div>
<p>
Demonstration in principle with the aim of verifying that the concept has practical potential.</p>
</div>
</div>
 </Col>
</Row>
</div>

<div className={globalstyle.timelineSection}>
 <Row gutter={[24]}>

 <Col md={3} sm={3} xs={0}> 
</Col>

 <Col md={3} sm={3} xs={2}> 
 
 <div className={homeStyle.vLineShort}>      
 {/* <img src={tr} /> */}
 </div>
  </Col>
  <Col md={12} sm={12} xs={22}> 
 
<div className={homeStyle.darkBgShort}>      
<div className={globalstyle.blockText}>  
<div className={globalstyle.smallColorHeading} style={{color:"#FF4094"}}>April 2021</div>
<div className={globalstyle.bigHeading}>Prototype Development.</div>

<p>
Development of a working prototype inline with user requirements.</p>
</div>
</div>
 </Col>
</Row>
</div>





<div className={globalstyle.timelineSection}>
 <Row gutter={[24]}>
 <Col md={3} sm={3} xs={0}> 
</Col>
 <Col md={3} sm={3} xs={2}> 
 
 <div className={homeStyle.vLineShort}>      
 </div>
  </Col>
<Col md={12} sm={12} xs={22}> 
<div className={homeStyle.darkBgShort}>      
<div className={globalstyle.blockText}>  
<div className={globalstyle.smallColorHeading} style={{color:"#FF4094"}}>October 2021</div>
<div className={globalstyle.bigHeading}>Test integrations of Fynya.</div>
{/* <p>By the end of 2021 we plan to release Fynya on mainnet, run alpha tests with our partners, on</p> */}
<p>In end of 2021 we plan to conduct alpha test where we will invite developers (in a bounty program) to help test scalability, performance, and security of Fynya.</p>
</div>
</div>
 </Col>

</Row>
 
</div>




<div className={globalstyle.timelineSection}>
 <Row gutter={[24]}>
 <Col md={3} sm={3} xs={0}> 
</Col>
 <Col md={3} sm={3} xs={2}> 
 
 <div className={homeStyle.vLineShort}>      
 </div>
  </Col>
<Col md={12} sm={12} xs={22}> 
 
<div className={homeStyle.darkBgShort}>      
<div className={globalstyle.blockText}>  



<div className={globalstyle.smallColorHeading} style={{color:"#FF4094"}}>December 2021</div>
<div className={globalstyle.bigHeading}>Launch of technology and product.</div>

{/* <p>Launch the product and start onboarding.</p> */}


</div>
</div>
 </Col>
</Row>
</div>

<div className={globalstyle.timelineSection}>
 <Row gutter={[24]}>
 <Col md={6} sm={6} xs={2}> 
</Col>

<Col md={12} sm={12} xs={22}> 
 
<div className={homeStyle.darkBgShort}>      
<div className={globalstyle.blockText}>  
<div className={globalstyle.bigHeading}>Conclusion.</div>
<p>
As evident from the roadmap, we have already completed a large amount of work that will enable commodity traders to 
leverage blockchain technology at scale. We also have a solid plan ahead for 
optimizing Fynya existing layer-2 scaling solution, while also fully decentralizing Trade Finance.
</p>

</div>
</div>
 </Col>

</Row>
 
</div>



</Zoom>


</div>
</div>
</div>
<br/>
<br/>
<br/>
<RequestDemo />

</>
    );
};

export default Roadmap;