import React, { useState } from 'react';
import style from './footer.module.css';
import globalstyle from '../../style.module.css';
import { Col, Row } from 'antd';
import "antd/dist/antd.css";
import { useCookies } from 'react-cookie'

import PhoneInput from 'react-phone-number-input'
import homeStyle from '../home/homeStyle.module.css';

import logo from '../../assets/img/logo.png';
import CookieBar from '../cookieBar/cookieBar';
import { Link } from 'react-router-dom';
import SocialIcons from './socialIcons';

const demoInputs = [
    {
       type: "number",
       name: "age",
       label: "Mobile Number",
       min : 18,
       max : 150,
       required: true,
       value: "/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i",
       message: "Invalid Age"
   
     }
    ]

const Footer = () => {
    const [cookies, setCookie, removeCookie] = useCookies(["acceptCookie"]);

    const [value, setValue] = useState()

return (
<div>
<div className={style.footer2}>
<div  className={globalstyle.container}>   




<Row gutter={[24]}>
<Col md={6} sm={6} xs={12}>
<div className={style.footerLinkColumn}>    
<Link><strong className={globalstyle.tinyHeading}>Industry solutions</strong></Link>  
<Link to="/blockchain-letter-of-credit">Blockchain LCs</Link>   
<Link to="/fynya-market">Tokenised Marketplace</Link>  
<Link to="/kyc-module">Blockchain KYC</Link>  
</div>
</Col>
<Col md={5} sm={5} xs={12}>

<div className={style.footerLinkColumn}>    
<Link><strong className={globalstyle.tinyHeading}>Company</strong></Link>  
<Link to="about">About Us</Link>  
<Link to="contact">Contact Us</Link>  
<Link to="team">Team</Link>   
</div>
 </Col>
 <Col md={5} sm={5} xs={12}>
 <div className={style.footerLinkColumn}>    
 <Link><strong className={globalstyle.tinyHeading}>Policies</strong></Link>  
<Link to="privacy">Privacy Policy</Link>   
</div>
</Col>

</Row> 

</div>    
</div> 

<div className={style.secondFooter}>  
<div  className={globalstyle.container}>   
<Row gutter={[24]}>
<Col md={12} sm={12} xs={10}>
<SocialIcons />
</Col>

<Col md={12} sm={12} xs={14}>
 <div className={style.footerLinkColumn}>  
    
<p  className={style.footerDisclaimer}>Copyright 2021. All Rights Reserved</p>

</div>
</Col>
</Row>
</div>
</div>

{
 cookies.acceptCookie ?    
null:
<CookieBar /> 

}

 </div>
);

};

export default Footer;