import React, { useEffect } from "react";

const PriceTicker = () => {
  useEffect(() => {
    if (document.getElementById("chart")) {
      const script = document.createElement("script");
      script.src =
        "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
      script.async = true;
      script.innerHTML = JSON.stringify({
        symbols: [
          {
            "description": "GOLD",
            "proName": "TVC:GOLD"
          },
          {
            "description": "COCOA",
            "proName": "ICEEUR:C1!"
          },
          {
            "description": "OIL",
            "proName": "OIL"
          },
          {
            "description": "RICE",
            "proName": "NYSE:RICE.U"
          },
          {
            "description": "COFFEE",
            "proName": "OTC:LKNCY"
          },
          {
            "description": "ENERGY",
            "proName": "BSE:ENERGY"
          },
          {
            "description": "LIVESTOCK",
            "proName": "NSENG:LIVESTOCK"
          },
          {
            "description": "SILVER",
            "proName": "TVC:SILVER"
          }
        ],
        colorTheme: "light",
        isTransparent: false,
        displayMode: "adaptive",
        locale: "en",
      });

      document.getElementById("chart").appendChild(script);
    }
  });

  return <div id="chart"></div>;
};

export default PriceTicker;