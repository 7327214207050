import React from 'react';
import style from './footer.module.css';
import { TwitterOutlined, FacebookOutlined, InstagramOutlined, YoutubeOutlined, LinkedinOutlined } from '@ant-design/icons';

const SocialIcons = () => {
    return (
<div className={style.socialIcons}>
<a title="facebook" href="https://twitter.com/fynyatrade"><TwitterOutlined style={{color: "#0E5FBD",fontSize:"22px", backgroundColor:"#fff",borderRadius:"3px",padding:"4px"}} /></a>   
<a title="twitter" href="https://www.facebook.com/Fynya-102072155068777"><FacebookOutlined style={{color: "#0E5FBD",fontSize:"22px", backgroundColor:"#fff",borderRadius:"3px",padding:"4px"}}  /></a>     
<a title="linkedin" href="https://www.instagram.com/fynyatrade/"><InstagramOutlined style={{color: "#0E5FBD",fontSize:"22px", backgroundColor:"#fff",borderRadius:"3px",padding:"4px"}}  /></a>  
<a title="linkedin" href="https://www.linkedin.com/company/fynya/"><LinkedinOutlined style={{color: "#0E5FBD",fontSize:"22px", backgroundColor:"#fff",borderRadius:"3px",padding:"4px"}} /> </a>
</div> 
    );
};

export default SocialIcons;