import React from 'react';
import MetaTags from 'react-meta-tags';
import { Col, Row } from 'antd';
import modules from './modules.module.css';
import globalstyle from '../../style.module.css';
import fynyapay from './fynyapay.jpg';
import RequestDemo from '../requestDemo/requestDemo';

const FynyaPay = () => {
return (
<div>
<MetaTags>
            <title>Fynya | Fynya Token</title>
            <meta name="description" content="Minimal fees, fast and secure transactions as result of Fynya token which has a fixed value and no use outside the platform.

" />
            <meta property="og:title" content="Minimal fees, fast and secure transactions" />
            <meta property="og:image" content="../../assets/img/logo.png" />
</MetaTags>
<div className={modules.homeMain}>      
<div className={modules.openingText}>
   <span className={modules.openingTextHeader}> Fynya Pay. </span>

 
 

</div>
</div>
<div className={globalstyle.container}>
<div className={globalstyle.bodySection}>



 <div className={globalstyle.bodySection}>            
 <div className={globalstyle.bodySectionRow}>            
<Row gutter={[24]}>
<Col md={12} xs={24}> 
<div>
{/* <span className={globalstyle.mainHeading}>Vision</span> */}
<p className={modules.description}>
Minimal fees, fast and secure transactions as result of Fynya token which has a fixed value and no use outside the platform.
Flexibility of transacting in other Fiat currencies.
 
 </p>
 
{/* <p className={modules.description}>
    Fynya aims to connect demand and supply side of commodity markets. We connect producers of 
    commodities directly with consumers from across the world hence increasing transparency and 
    information dissemination between buyers and sellers. 
</p> */}

</div>
</Col>
<Col md={12} xs={24} className={globalstyle.bgAboutPicture}> 
<img className={globalstyle.picture} src={fynyapay} />
</Col>
</Row>
</div>

</div>

</div>
</div>
<RequestDemo />
</div>
    );
};

export default FynyaPay;