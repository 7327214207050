import React from 'react';
import MetaTags from 'react-meta-tags';
import { Col, Row } from 'antd';
import { FileProtectOutlined, BankOutlined} from '@ant-design/icons';
import modules from './modules.module.css';
import globalstyle from '../../style.module.css';
import commodityTrade from '../../assets/img/commodityTrade.png';
import products from './products.module.css';
import RequestDemo from '../requestDemo/requestDemo';
import PriceTicker from '../priceTicker/priceTicker';


const CommodityTrade = () => {
return (
<div>
<MetaTags>
            <title>Fynya | Commodity Trading.</title>
            <meta name="description" content="Access and Trade wide range of commodities on our easy-to-use Commodity Trading Platform." />
            <meta property="og:title" content="Fynya | Commodity Trading" />
            <meta property="og:image" content="../../assets/img/logo.png" />
</MetaTags>
<div className={globalstyle.mainPageBanner}>      
<div className={globalstyle.openingText}>
<span className={globalstyle.openingTextHeader}>  Commodity Trading. </span>

   <br />
     <span className={globalstyle.openingTextDescription}>Access wide range of commodity markets.
</span> 
        <br/>
 
 
 
</div>


</div>


<PriceTicker />






{/* <Slider /> */}
<div className={globalstyle.container}>
 


 <div className={globalstyle.bodySection}>            
 <div className={globalstyle.bodySectionRow}>            
<Row gutter={[24]}>
<Col md={12} xs={24} className={globalstyle.bgAboutPicture}> 
<img className={globalstyle.picture} src={commodityTrade} />
</Col>
<Col md={12} xs={24}> 
<div>

<p className={modules.description}>
Access and Trade wide range of commodities on our easy-to-use Commodity Trading Platform.
Our platform is built on
powerful sophisticated technology and robust infrastructure, backed by an ecosystem of in-house tailor-made, custom built modules to increase yor commodity trading experience.


Choose from a wide variety of commodities, while benefiting from the latest real-time technology and available commodity prices. 
<br/>
Get access to commodity prices worldwide with high execution speeds, low slippage,deep liquidity and tight spreads.

</p>


 

</div>
</Col>

</Row>
</div>

</div>
 
</div>


<div style={{backgroundColor:"#271D58"}}>
<div className={globalstyle.container}>

<div className={globalstyle.bodySection}>     

<div className={globalstyle.heading} style={{color:"#fff",paddingBottom:"29px"}}>Get Started In 3 Easy Steps</div>


        <Row gutter={[24]}>
        <Col md={8} xs={24}> 
<p className={modules.descriptionWhite}>
<div className={globalstyle.heading}>Register</div>

<Row gutter={[24]}>
<Col md={4} xs={4}>
<div className={globalstyle.stepNumber}>
1
</div>
</Col>
<Col md={20} xs={20}>
<p className={globalstyle.stepNumberText}>
Create a new account by filling out the application form and submitting.

</p>
</Col>
</Row>
</p>

</Col>
<Col md={8} xs={24}> 
<p className={modules.descriptionWhite}>
<div className={globalstyle.heading}>Fund</div>

<Row gutter={[24]}>
<Col md={4} xs={4}>
<div className={globalstyle.stepNumber}>
2
</div>
</Col>
<Col md={20} xs={20}>
<p className={globalstyle.stepNumberText}>
Fund your account using a wide range of funding methods.
Once your account is funded, you’re ready to trade.
</p>
</Col>
</Row>
</p>

</Col>
<Col md={8} xs={24}> 
<p className={modules.descriptionWhite}>
<div className={globalstyle.heading}>Trade</div>

<Row gutter={[24]}>
<Col md={4} xs={4}>
<div className={globalstyle.stepNumber}>
3
</div>
</Col>
<Col md={20} xs={20}>
<p className={globalstyle.stepNumberText}>
Access range of instruments on our platform
 and benefit from low fees and advanced trading features.

</p>
</Col>
</Row>
</p>

</Col>
 
</Row>
 
</div>
</div>
</div>


<div className={globalstyle.container}>

<div className={globalstyle.bodySection}>


<Row gutter={[24]}>

<Col md={6} sm={12} xs={24}> 

 <div className={products.advantage}>      
<span className={products.productIcon}><FileProtectOutlined /></span>
<div className={globalstyle.heading}>Fast execution
</div>
  <p style={{textAlign:"center"}}>
  We offer our clients high order execution speed for a comfortable experience.

</p>
</div>
   </Col>

<Col md={6} sm={12} xs={24}> 
 
 <div className={products.advantage}>      
<span className={products.productIcon}><FileProtectOutlined /></span>
<div className={globalstyle.heading}>Execution Transparency</div>

<p style={{textAlign:"center"}}>
Enhanced trading strength at better speed with 4 types of executions available, including superior tools for technical analysis.
 </p>


 </div>
</Col>

<Col md={6} sm={12} xs={24}> 
 <div className={products.advantage}>      
<span className={products.productIcon}><BankOutlined /></span>
<div className={globalstyle.heading}>Competitive pricing

</div>
<p style={{textAlign:"center"}}>
Receive the best prices when trading your favored assets.
Tight spreads and low commissions across our range of commodities.
 </p>
</div>
 </Col>

<Col md={6} sm={12} xs={24}> 
 <div className={products.advantage}>      
<span className={products.productIcon}><FileProtectOutlined /></span>
<div className={globalstyle.heading}>Funds Security
</div>
<p style={{textAlign:"center"}}>
Our top priority is to ensure the adequate levels of your fund’s safety. Secure your deposits and experience fast withdrawals.
 </p>
</div>
 </Col>

</Row>
 </div>
</div>
<RequestDemo />

</div>
    );
};

export default CommodityTrade;