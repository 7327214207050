import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
import { Col, Row } from 'antd';
import { SlidersOutlined, FileProtectOutlined, SolutionOutlined, BankOutlined} from '@ant-design/icons';
import products from './products.module.css';
import homeStyle from '../home/homeStyle.module.css';
import globalstyle from '../../style.module.css';
import platforms from '../../assets/img/platforms.png';

import first from '../../assets/img/target/1.png';
import second from '../../assets/img/target/2.png';
import third from '../../assets/img/target/3.png';
import fourth from '../../assets/img/target/4.png';


const Features = () => {

const [ active, setActive ] = useState (true);

return (
<div className={globalstyle.bodySection}>
<div className={globalstyle.featureSection}>

<div className={homeStyle.openingTextDescription} >One liberised system for all.<br/> <span style={{textDecoration:"underline",textDecorationColor:"#0aa9c2"}}>None</span> of the fluff.</div> 
<br/>
<Row gutter={[24]}>
 
<Col md={24} sm={24} xs={24}> 

<Row gutter={[24]}>

 

<Col md={6} sm={6} xs={12} onMouseEnter={() => setActive(true)}  className={ active?homeStyle.targetItemActive:homeStyle.targetItem } > 
<span className={homeStyle.productIcon}>     
<img src={third} className={homeStyle.targetImage}/>   
</span>
<div className={homeStyle.smallHeading}>Tokenised <br/> Platform</div>

</Col>

<Col md={6} sm={4} xs={12}  className={ homeStyle.targetItem }> 
<span className={homeStyle.productIcon}>     
<img src={second} className={homeStyle.targetImage}/>   
</span>
<div className={homeStyle.smallHeading}>Self-executing  <br/> smart contracts</div>

</Col>
<Col md={6} sm={4} xs={12}  className={ homeStyle.targetItem }> 
<span className={homeStyle.productIcon}>     
<img src={third} className={homeStyle.targetImage}/>   
</span>
<div className={homeStyle.smallHeading}>Simplified  <br/>Crypto Payments</div>

</Col>

<Col md={6} sm={4} xs={12}  className={ homeStyle.targetItem }> 
<span className={homeStyle.productIcon}>     
<img src={fourth} className={homeStyle.targetImage}/>   
</span>
<div className={homeStyle.smallHeading}>Blockchain  <br/>Letter of Credit </div>

</Col>


</Row>

</Col>


</Row>

</div>
</div>
    );
};

export default Features;