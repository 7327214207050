import React from 'react';
import { Col, Row } from 'antd';
import MetaTags from 'react-meta-tags';
import pagesStyle from './pages.module.css';
import globalstyle from '../../style.module.css';
import SocialIcons from '../footer/socialIcons';
import modules from '../products/modules.module.css';
import RequestDemo from '../requestDemo/requestDemo';
import cont from '../../assets/img/contact.jpg';

const Contact = () => {
    return (
<>
<MetaTags>
            <title>Fynya | Contact Us</title>
            <meta name="description" content="Fynya | Contact Us" />
            <meta property="og:title" content="Fynya’s vision is to provide a solution to simplify the International Commodities Trading Lifecycle Management issues, protect investor money and boost user confidence by using the capability of Blockchain." />
            <meta property="og:image" content="../../assets/img/logo.png" />
</MetaTags>
<div className={modules.homeMain}>      
<div className={modules.openingText}>
   <span className={modules.openingTextHeader}> Contact Us </span>

</div>
</div>
 <div className={globalstyle.container}>            
 <div className={pagesStyle.contactsBody}>            
<Row gutter={[24]}>
<Col md={12} sm={12} xs={24}> 
<div className={pagesStyle.contacts}>

<p className={pagesStyle.description}>
Thanks for your interest in Fynya!
Have a question ?
Please email us on the email below or fill out the form.
</p>

</div>
<br/>
<div className={pagesStyle.contacts}>

<div className={pagesStyle.mainHeading}>info@fynya.com</div>

</div>
<div className={pagesStyle.socialIcons}>
<Row gutter={[24]}>
<Col md={6} xs={10}> 
<span>Follow us on </span>
</Col>
<Col md={17} xs={14}> 
<SocialIcons />
</Col>
</Row>
</div>


</Col>
<Col md={12} sm={12} xs={0} style={{padding:"0 80px"}} className={globalstyle.bgAboutPicture}> 
<img className={globalstyle.picture} src={cont} />
{/* <RequestDemoForm /> */}

</Col>
</Row>
 

</div>

</div>
<RequestDemo />

</>
    );
};

export default Contact;